<template>
    <v-container>
        <v-row dense>
            <v-col cols="12" class="mb-6">
                <div class="header_holder text-center ma">
                    <div class="rounder_top mb-2">
                        <div class="card_header ubuntu_b primer">
                            Provjeri narudžbenicu
                        </div>
                    </div>
                </div>
                <v-btn color="#eee" text small class="white_circle back" @click="back">Vrati se u košaricu</v-btn>
                <OrderCheckout />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import OrderCheckout from '../components/OrderCheckout.vue'
export default {
    data() {
        return {
        }
    },
    components: { OrderCheckout },
    methods: {
        back(){
            this.$router.go(-1)
        }
    }
}
</script>
<style scoped>
    .card_header{
        position:relative;
        height:47px;
        line-height:47px;
        text-align:center;
    }
    .back{
        position:absolute;
        width:26px;
        height:26px;
        left:13px;
        top:13px;
    }
    .continue{
        position:absolute;
        width:26px;
        height:26px;
        right:13px;
        top:13px;
    }
</style>
